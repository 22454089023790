<template>
  <section id="content">
    <PageTitle :title="$t('configuration.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">
      <div class="row">
        <div class="col-lg-12">
          <Workers v-if="isModuleAllowed('api/eurofiscalis/workers')" :config="getModuleConfiguration('api/eurofiscalis/workers')"></Workers>
          <Portal v-if="isModuleAllowed('app')" :config="getModuleConfiguration('app')"></Portal>  
          <Tasks v-if="isModuleAllowed('app')" :config="getModuleConfiguration('app')['tasks']"></Tasks>  
          <Payment v-if="isModuleAllowed('api/payment')" :config="getModuleConfiguration('api/payment')"></Payment>
          <Email v-if="isModuleAllowed('api/email')" :config="getModuleConfiguration('api/email')"></Email>
          <Authentication v-if="isModuleAllowed('api/passport')" :config="getModuleConfiguration('api/passport')"></Authentication>
          <Shop v-if="isModuleAllowed('api/shop')" :config="getModuleConfiguration('api/shop')"></Shop>
        </div>
      </div>
    </div>
  </section>
</template>


<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../panels/PageTitle.vue';
import * as api from '@fwk-client/utils/api';

@Component({
  components: { 
    PageTitle : PageTitle,
    Workers : () => import(/* webpackChunkName: "panels-config-eurofiscalis-workers" */ '../panels/configuration/eurofiscalis/Workers.vue'),
    Portal : () => import(/* webpackChunkName: "panels-config-portal" */ '../panels/configuration/Portal.vue'),
    Tasks : () => import(/* webpackChunkName: "panels-config-portal-tasks" */ '../panels/configuration/Tasks.vue'),
    Payment : () => import(/* webpackChunkName: "panels-config-payment" */ '../panels/configuration/Payment.vue'),
    Email : () => import(/* webpackChunkName: "panels-config-email" */ '../panels/configuration/Email.vue'),
    Authentication : () => import(/* webpackChunkName: "panels-config-authentication" */ '../panels/configuration/Authentication.vue'),
    Shop : () => import(/* webpackChunkName: "panels-config-shop" */ '../../modules/shop/components/panels/configuration/Shop.vue')
   }
})
export default class Configuration extends mixins(GenericPage) {

  configModules:any = {};

  isModuleAllowed(moduleName:string) {  
    return this.configModules[moduleName] !== undefined;
  }

  created() {
    this.getAvailableConfiguration();
  }

  /**
   * getAvailableConfiguration
   * This method retrieve the configuration the user is allowed to modify.
   */
  getAvailableConfiguration() {
    // We need to get the list of available roles for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    api.getAPI('/api/config/available-modules', options).then((response:any) => {
      if(response.modules) {  
        this.configModules = response.modules;
      }
    });
  }

  getModuleConfiguration(moduleName:string) {
    return this.configModules[moduleName];
  }
  
}
</script>